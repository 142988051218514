<template>
  <v-container id="subscribe" tag="section">
    <v-card color="#dcedc8" class="pa-3" rounded>
      <v-container>
        <v-row>
          <v-col cols="12" md="5">
            <base-subheading class="mb-3">
              Subscribe
            </base-subheading>

            <p>
              Enter your email address to subscribe to this site
              and receive notifications of new posts by email.
            </p>

            <v-row class="pa-2">
              <v-responsive class="overflow-visible mr-2" max-width="400">
                <v-text-field hide-details label="Your Email Address" solo v-model="email" />
              </v-responsive>

              <v-btn :block="$vuetify.breakpoint.xsOnly" class="ma-0" color="secondary" style="height: 48px" @click="subscribe()">
                Subscribe
              </v-btn>
            </v-row>
          </v-col>

          <v-col cols="12" md="6" offset-md="1">
            <base-subheading>Contact</base-subheading>

            <v-list class="transparent">
              <v-list-item>
                <v-list-item-avatar color="primary">
                  <v-icon class="elevation-4" dark>
                    mdi-email-outline
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-title>EMAIL</v-list-item-title>

                <v-list-item-subtitle>
                  <tt>the.dac.guide@gmail.com</tt>
                </v-list-item-subtitle>
              </v-list-item>

            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
     <v-snackbar v-model="show_message" timeout="5000" centered :color="message_colour" auto-height>
       <v-layout align-center pr-4>
         <v-icon class="pr-3" dark large>{{ message_icon }}</v-icon>
         <v-layout column>
           <div>
             <strong>{{ error_header }}</strong>
           </div>
           <div>{{ error_text }}</div>
         </v-layout>
       </v-layout>

       <template v-slot:action="{ attrs }">
         <v-btn color="blue" text v-bind="attrs" @click="show_message = false">Close</v-btn>
       </template>
     </v-snackbar>
  </v-container>
</template>

<script>
import SubscribeService from '@/services/SubscribeService.js';

export default {
  name: 'Subscribe',

  components: {
  },

  data: () => ({
    email: '',
    show_message: false,
    message_colour: '',
    message_icon: '',
    error_header: '',
    error_text: ''
  }),

  methods: {
    subscribe() {
      console.log('subscribe');

      if ((this.email.length === 0) || (this.email.indexOf('@') === -1)) {
        this.show_message = true;
        this.error_header = 'Note';
        this.error_text = 'Please enter an email address';
        this.message_colour = '#ffa000';
        this.message_icon = 'mdi-alert';
      }
      else {
        const self = this;

        // check email address
        SubscribeService.subscribe(this.email)
                        .then(function(response) {
                          console.log('user subscribed, response [' + JSON.stringify(response).substr(0, 100) + ']');
                          if (response.data.error) {
                            self.error_header = 'Error';
                            self.message_colour = '#d32f2f';
                            self.message_icon = 'mdi-alert-circle';
                            self.error_text = response.data.message;
                            self.show_message = true;
                          }
                          else {
                            if (response.data.warning) {
                              self.show_message = true;
                              self.error_header = 'Note';
                              self.error_text = response.data.message;
                              self.message_colour = '#ffa000';
                              self.message_icon = 'mdi-alert';
                            }
                            else {
                              self.error_header = 'Info';
                              self.error_text = response.data.message;
                              self.message_icon = 'mdi-emoticon-happy';
                              self.message_colour = '#4fc3f7';
                              self.show_message = true;
                            }
                          }  // else, no error
                        })
                        .catch(function(error) {
                          console.log('subscribe error [' + JSON.stringify(error).substr(0, 100) + ']');
                          self.error_header = 'Error';
                          self.message_colour = '#d32f2f';
                          self.message_icon = 'mdi-alert-circle';
                          self.error_text = error.message;
                          self.show_message = true;
                        });
        //    }  // if email field valid
      }  // else, text in the email field
    }  // subscribe
  }  // methods

}
</script>
